@font-face {
font-family: '__beholdenFont_c90576';
src: url(/_next/static/media/d737d1abe86580c5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__beholdenFont_c90576';
src: url(/_next/static/media/8d4725f182cbd6e5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__beholdenFont_c90576';
src: url(/_next/static/media/bc3ec9b479800639-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__beholdenFont_c90576';
src: url(/_next/static/media/c3186a9108301d0d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__beholdenFont_c90576';
src: url(/_next/static/media/0b1aa866b9514264-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__beholdenFont_c90576';
src: url(/_next/static/media/ddc9b3b3d7d7cd3a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__beholdenFont_Fallback_c90576';src: local("Arial");ascent-override: 99.74%;descent-override: 27.02%;line-gap-override: 0.00%;size-adjust: 88.90%
}.__className_c90576 {font-family: '__beholdenFont_c90576', '__beholdenFont_Fallback_c90576'
}

